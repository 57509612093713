var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"w4PxTQlrKctyuFoMrFeWt"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { initSentry } from "@/utils/instrumentation";
import config from "@/client/config";

initSentry(config.SENTRY_DSN(), config.APP_ENV(), config.SENTRY_TRACE_RATE());
